import React from "react";
import styled from "styled-components";

const ContentPageContainer = styled.div(
   (props) => `
    display: flex;
    justify-content: center;
    background: ${props.background};
    padding: 160px 0;

     @media only screen and (max-width: 600px) {
     padding: 100px 0;
   }
`
);

const InnerContainer = styled.div`
   display: flex;
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
`;

const ContentPage = ({ children, background, mobile }) => {
   return (
      <ContentPageContainer background={background} mobile={mobile}>
         <InnerContainer>{children}</InnerContainer>
      </ContentPageContainer>
   );
};

export default ContentPage;
