import LandingContent from "../homePage/landingContent";
import Footer from "../homePage/footer";
import "../font-awesome";
import * as React from "react";
import styled from "styled-components";
import AboutUsContent from "../homePage/aboutUs";
import CalculatorContent from "../homePage/calculator";
import BenefitsContent from "../homePage/benefits";
import ServicesContent from "../homePage/services";
import CalculatorCard from "../homePage/calculatorCard";
import TechnologiesContent from "../homePage/technologies";
import HiringContent from "../homePage/hiring";

const Container = styled.div`
   display: flex;
   height: 100%;
   scroll-behavior: smooth;
`;

const MainContent = styled.div`
   flex: 1;

   .sectionWrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }

   .rectangle {
      position: absolute;
      z-index: -1;
      transform: translateY(-23rem);
   }
`;

const Home = ({ isMobile }) => {
   return (
      <div className="desktopHome">
         <Container>
            <MainContent>
               <LandingContent />
               <AboutUsContent isMobile={isMobile} />
               <CalculatorContent isMobile={isMobile} />
               <BenefitsContent isMobile={isMobile} />
               <ServicesContent isMobile={isMobile} />
               {!isMobile && <CalculatorCard />}
               <TechnologiesContent />
               <HiringContent isMobile={isMobile} />
               <Footer isMobile={isMobile} />
            </MainContent>
         </Container>
      </div>
   );
};

export default Home;
