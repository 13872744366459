import React, { useState } from "react";
import styled from "styled-components";
import ContentPage from "./contentPage";
import ChevronUp from "../../images/symbols/chevronUp.svg";
import ChevronDown from "../../images/symbols/chevronDown.svg";
import Button, { ButtonTypes, ButtonVariants } from "../atoms/button";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Container = styled.div`
   display: flex;
   width: 100%;
   gap: 64px;

   @media only screen and (max-width: 600px) {
      flex-direction: column;
   }
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 16px;
   color: var(--text-primary);

   .tagline {
      color: var(--accent-primary);
   }

   .headingContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .buttonsContainer {
      display: flex;
      gap: 10px;
      margin-top: 24px;
   }

   @media only screen and (max-width: 600px) {
      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const TechnologiesSection = styled.div`
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: 600px;
   flex-shrink: 0;

   @media only screen and (max-width: 600px) {
      width: unset;
   }
`;

const AccordionWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 16px;
   padding: 16px 24px;
   background-color: var(--medium-bg);
   border-radius: 8px;
   cursor: pointer;

   .top-accordion {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   .accordion-title {
      font-weight: 700;
      color: var(--text-primary);
   }

   .chevron-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: ${(props) =>
         props.isOpen ? "var(--accent-primary)" : "transparent"};
      color: ${(props) =>
         props.isOpen ? "var(--text-inverted)" : "var(--text-primary)"};
      width: 36px;
      height: 36px;
   }

   .technologyImagesWrapper {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
   }

   .technologyImagesWrapper img {
      width: 64px;
   }
`;

const TechnologyAccordion = ({
   technology,
   openSection,
   handleOpenSection,
}) => {
   const { section, technologiesImgs } = technology;
   const isOpen = openSection === section;

   return (
      <AccordionWrapper
         onClick={() => handleOpenSection(section)}
         isOpen={isOpen}
      >
         <div className="top-accordion">
            <p className="accordion-title">{section}</p>
            <div className="chevron-wrapper">
               {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
         </div>
         {isOpen && (
            <div className="technologyImagesWrapper">{technologiesImgs}</div>
         )}
      </AccordionWrapper>
   );
};

const TechnologiesContent = () => {
   const [openSection, setOpenSection] = useState("Frontend");
   const data = useStaticQuery(query);

   const technologies = [
      {
         section: "Frontend",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.angular.childImageSharp.gatsbyImageData}
                  alt="Angular"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.react.childImageSharp.gatsbyImageData}
                  alt="React"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.javascript.childImageSharp.gatsbyImageData}
                  alt="JavaScript"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.vue.childImageSharp.gatsbyImageData}
                  alt="Vue"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.threejs.childImageSharp.gatsbyImageData}
                  alt="ThreeJS"
                  loading="eager"
               />
            </>
         ),
      },
      {
         section: "Backend",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.java.childImageSharp.gatsbyImageData}
                  alt="Java"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.dotnet.childImageSharp.gatsbyImageData}
                  alt=".NET"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.php.childImageSharp.gatsbyImageData}
                  alt="PHP"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.cplusplus.childImageSharp.gatsbyImageData}
                  alt="C & C++"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.csharp.childImageSharp.gatsbyImageData}
                  alt="C#"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.python.childImageSharp.gatsbyImageData}
                  alt="Python"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.nodejs.childImageSharp.gatsbyImageData}
                  alt="Node.js"
                  loading="eager"
               />
            </>
         ),
      },
      {
         section: "Deployment",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.kafka.childImageSharp.gatsbyImageData}
                  alt="Kafka"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.docker.childImageSharp.gatsbyImageData}
                  alt="Docker"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.terraform.childImageSharp.gatsbyImageData}
                  alt="Terraform"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.kubernetes.childImageSharp.gatsbyImageData}
                  alt="Kubernetes"
                  loading="eager"
               />
            </>
         ),
      },
      {
         section: "Mobile",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.reactnative.childImageSharp.gatsbyImageData}
                  alt="React Native"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.flutter.childImageSharp.gatsbyImageData}
                  alt="Flutter"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.android.childImageSharp.gatsbyImageData}
                  alt="Android"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.ios.childImageSharp.gatsbyImageData}
                  alt="iOS"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.kotlin.childImageSharp.gatsbyImageData}
                  alt="Kotlin"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.swift.childImageSharp.gatsbyImageData}
                  alt="Swift"
                  loading="eager"
               />
            </>
         ),
      },
      {
         section: "Cloud",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.azure.childImageSharp.gatsbyImageData}
                  alt="Azure"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.aws.childImageSharp.gatsbyImageData}
                  alt="AWS"
                  loading="eager"
               />
            </>
         ),
      },
      {
         section: "CMS Systems",
         technologiesImgs: (
            <>
               <GatsbyImage
                  image={data.wordpress.childImageSharp.gatsbyImageData}
                  alt="WordPress"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.liferay.childImageSharp.gatsbyImageData}
                  alt="Liferay"
                  loading="eager"
               />
               <GatsbyImage
                  image={data.drupal.childImageSharp.gatsbyImageData}
                  alt="Drupal"
                  loading="eager"
               />
            </>
         ),
      },
   ];

   const handleOpenSection = (section) => {
      setOpenSection(openSection === section ? undefined : section);
   };

   const delayFactor = (index) => 100 * index;

   return (
      <ContentPage background="var(--light-bg)">
         <Container>
            <TextContainer>
               <div className="headingContainer">
                  <p
                     className="tagline"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(0)}
                  >
                     TECHNOLOGIES
                  </p>
                  <h2 data-aos="fade-up" data-aos-delay={delayFactor(1)}>
                     Access Top Talent in Leading Technologies
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={delayFactor(2)}>
                     Our Nearshore offering provides fast access to a highly
                     experienced development team skilled in the most in-demand
                     technologies. From frontend frameworks like React and
                     Angular to backend solutions such as Node.js, Java, .NET,
                     C/C++, Python, and PHP, we ensure you receive top-quality
                     resources at the most competitive rates.
                  </p>
                  <p data-aos="fade-up" data-aos-delay={delayFactor(2)}>
                     In addition to developers, Nearshore.pt can also supply
                     experts in UI/UX design, business analysis, and even
                     product ownership, tailored to meet your specific needs.
                  </p>
                  <div className="buttonsContainer">
                     <Button
                        content="Contact us"
                        type={ButtonTypes.PRIMARY}
                        url="/contact-us"
                        data-aos="fade-up"
                        data-aos-delay={delayFactor(3)}
                     />
                     <Button
                        content="Calculate for free"
                        type={ButtonTypes.SECONDARY}
                        variant={ButtonVariants.PRIMARY_COLOR}
                        url="/hirewise"
                        data-aos="fade-up"
                        data-aos-delay={delayFactor(3)}
                     />
                  </div>
               </div>
            </TextContainer>
            <TechnologiesSection>
               {technologies.map((t) => (
                  <TechnologyAccordion
                     technology={t}
                     openSection={openSection}
                     handleOpenSection={handleOpenSection}
                     key={t?.section}
                  />
               ))}
            </TechnologiesSection>
         </Container>
      </ContentPage>
   );
};

export default TechnologiesContent;

const query = graphql`
   query TechnologiesQuery {
      # Frontend Technologies
      angular: file(relativePath: { eq: "technologies/Angular.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      react: file(relativePath: { eq: "technologies/ReactJS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      javascript: file(relativePath: { eq: "technologies/JavaScript.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      vue: file(relativePath: { eq: "technologies/VueJS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      threejs: file(relativePath: { eq: "technologies/ThreeJS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }

      # Backend Technologies
      java: file(relativePath: { eq: "technologies/Java.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      dotnet: file(relativePath: { eq: "technologies/DotNet.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      php: file(relativePath: { eq: "technologies/PHP.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      cplusplus: file(relativePath: { eq: "technologies/C & C++.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      csharp: file(relativePath: { eq: "technologies/C#.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      python: file(relativePath: { eq: "technologies/Python.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      nodejs: file(relativePath: { eq: "technologies/NodeJS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }

      # Deployment Technologies
      kafka: file(relativePath: { eq: "technologies/Kafka.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      docker: file(relativePath: { eq: "technologies/Docker.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      terraform: file(relativePath: { eq: "technologies/Terraform.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      kubernetes: file(relativePath: { eq: "technologies/Kubernetes.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }

      # Mobile Technologies
      reactnative: file(relativePath: { eq: "technologies/React Native.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      flutter: file(relativePath: { eq: "technologies/Flutter.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      android: file(relativePath: { eq: "technologies/Android.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      ios: file(relativePath: { eq: "technologies/iOS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      kotlin: file(relativePath: { eq: "technologies/Kotlin.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      swift: file(relativePath: { eq: "technologies/Swift.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }

      # Cloud Technologies
      azure: file(relativePath: { eq: "technologies/Azure.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      aws: file(relativePath: { eq: "technologies/AWS.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }

      # CMS Systems
      wordpress: file(relativePath: { eq: "technologies/Wordpress.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      liferay: file(relativePath: { eq: "technologies/Liferay.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
      drupal: file(relativePath: { eq: "technologies/Drupal.png" }) {
         childImageSharp {
            gatsbyImageData(width: 64, layout: FIXED, placeholder: NONE)
         }
      }
   }
`;
