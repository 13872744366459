import React from "react";
import styled from "styled-components";
import Button, { ButtonTypes, ButtonVariants } from "../atoms/button";

const CalculatorWrapper = styled.div`
   display: flex;
   width: 100%;
   justify-content: center;
   transform: translateY(-50%);

   @media only screen and (max-width: 600px) {
      transform: unset;
   }
`;

const CalculatorContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 64px;
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   box-sizing: border-box;
   padding: 64px;
   background: var(--gradient-bg);
   border-radius: 16px;
   box-shadow: 0px 4px 16px 0px #0000003d;

   .textContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 8px;
      color: var(--text-inverted);
   }

   .textContainer p {
      color: var(--text-muted-inverted);
   }

   @media only screen and (max-width: 600px) {
      margin: unset;
      flex-direction: column;
      gap: 32px;
      padding: 24px;

      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const CalculatorCard = () => {
   return (
      <CalculatorWrapper>
         <CalculatorContainer>
            <div className="textContainer">
               <h4>How much will I need to spend?</h4>
               <p>
                  Get an instant estimate on the costs of building or expanding
                  your team with our flexible Nearshore staffing solutions.
                  Enter your requirements in HireWise to see potential savings
                  and find the most cost-effective option for your project.
               </p>
            </div>
            <Button
               content="Calculate for free"
               type={ButtonTypes.PRIMARY}
               variant={ButtonVariants.WHITE}
               url="/hirewise"
            />
         </CalculatorContainer>
      </CalculatorWrapper>
   );
};

export default CalculatorCard;
