import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import ContentPage from "./contentPage";
import HireWiseSVG from "../../images/hirewise.svg";
import Button, { ButtonTypes } from "../atoms/button";

const Container = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   flex-direction: ${(props) =>
      props.orientationInverted ? "row-reverse" : "row"};

   .imageContainer {
      border-radius: 32px;
      aspect-ratio: 1;
      height: 460px;
   }

   @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 64px;

      .imageContainer {
         height: unset;
         width: 100%;
      }
   }
`;

const InfoWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 24px;
   max-width: 600px;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;
   color: ${(props) => props.textColor || "var(--text-primary)"};

   .tagline {
      color: ${(props) => props.taglineColor || "var(--accent-primary)"};
   }

   .subheading {
      max-width: 640px;
   }

   .bold {
      font-weight: 500;
   }

   .headingContainer {
      /* max-width: 440px; */
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .subHeadingContainer {
      max-width: 560px;
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
`;

const ButtonsContainer = styled.div`
   display: flex;
   gap: 16px;

   @media only screen and (max-width: 600px) {
      flex-direction: column;

      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const ContentSection = ({
   background,
   heading,
   tagline,
   boldSubheading,
   sectionName,
   imageAlt,
   textColor,
   taglineColor,
   orientationInverted = false,
   hasAnimations = true,
   isMobile,
}) => {
   const delayFactor = (index) => 100 * index;

   const sectionDetails = () => {
      let subheading;
      let Image;
      let button;
      switch (sectionName) {
         case "aboutUs":
            Image = (
               <StaticImage
                  src="../../images/developerCodingImg.jpeg"
                  className="imageContainer"
                  alt={imageAlt}
               />
            );
            subheading = (
               <>
                  <p
                     className="subheading"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(2)}
                  >
                     Caixa Mágica is renowned for excellence in IT services,
                     with customers and professionals attesting to the quality
                     of our solutions and the outstanding work environment we
                     foster. We operate in critical sectors such as eID,
                     Automotive, and Mobile App Store development, providing
                     services that go beyond standard talent search and
                     traditional nearshore offerings.
                  </p>
                  <p
                     className="subheading"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(2)}
                  >
                     Through Nearshore.pt, we're extending Caixa Mágica's
                     expertise across Europe, offering cost-effective,
                     high-impact staffing solutions to our European neighbours.
                  </p>
               </>
            );
            break;
         case "calculator":
            Image = <HireWiseSVG />;
            subheading = (
               <p className="subheading">
                  Our Staffing Calculator <strong>HireWise</strong> helps
                  businesses estimate the potential cost savings associated with
                  hiring IT professionals longterm or flexible for a few months.
                  By inputting your specific requirements, you can quickly and
                  accurately calculate the cost-effectiveness of our staffing
                  solutions.
               </p>
            );
            button = (
               <Button
                  content="Calculate for free"
                  type={ButtonTypes.PRIMARY}
                  url="/hirewise"
               />
            );
            break;
         default:
            subheading = "";
      }
      return { subheading, Image, button };
   };

   const { subheading, button } = sectionDetails();

   return (
      <ContentPage background={background}>
         <Container orientationInverted={orientationInverted}>
            <InfoWrapper>
               <TextContainer textColor={textColor} taglineColor={taglineColor}>
                  <div className="headingContainer">
                     <p
                        className="tagline"
                        {...(hasAnimations && {
                           "data-aos": "fade-up",
                           "data-aos-offset": 100,
                           "data-aos-delay": delayFactor(0),
                        })}
                     >
                        {tagline}
                     </p>
                     <h2
                        {...(hasAnimations && {
                           "data-aos": "fade-up",
                           "data-aos-offset": 100,
                           "data-aos-delay": delayFactor(1),
                        })}
                     >
                        {heading}
                     </h2>
                  </div>
                  <div className="subHeadingContainer">
                     {subheading}
                     {boldSubheading && (
                        <p
                           className="subheading bold"
                           {...(hasAnimations && {
                              "data-aos": "fade-up",
                              "data-aos-delay": delayFactor(2),
                           })}
                        >
                           {boldSubheading}
                        </p>
                     )}
                  </div>
               </TextContainer>
               {button && <ButtonsContainer>{button}</ButtonsContainer>}
            </InfoWrapper>
            {(!isMobile || sectionName === "aboutUs") && (
               <div
                  className="imgWrapper"
                  {...(hasAnimations && {
                     "data-aos": "fade-in",
                     "data-aos-delay": delayFactor(2),
                  })}
               >
                  {sectionDetails()?.Image}
               </div>
            )}
         </Container>
      </ContentPage>
   );
};

export default ContentSection;
