import React from "react";
import styled from "styled-components";
import ContentPage from "./contentPage";
import HelloSVG from "../../images/symbols/handWaving.svg";
import GearSVG from "../../images/symbols/gear.svg";
import FunnelSVG from "../../images/symbols/funnel.svg";
import RocketSVG from "../../images/symbols/rocketLaunch.svg";
import LifeBuoySVG from "../../images/symbols/lifebuoy.svg";
import Button, { ButtonTypes } from "../atoms/button";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   gap: 64px;
   color: var(--text-inverted);
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;

   .tagline {
      color: var(--accent-muted);
   }

   .headingContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 720px;
   }
`;

const StepContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 24px;
   border: 1px solid var(--light-bg-opacity-20);
   border-radius: 8px;
   gap: 20px;

   .svgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 6px;
      background-color: var(--light-bg-opacity-05);
   }

   .svgWrapper svg {
      width: 24px;
      height: 24px;
      color: var(--accent-muted);
   }

   .textContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
   }

   .step-heading {
      color: var(--accent-muted);
      font-weight: 700;
   }

   p {
      color: var(--text-muted-inverted);
   }

   &:hover {
      background-color: var(--light-bg-opacity-05);
      border: 1px solid var(--accent-muted);
      transition-delay: 200ms;
   }
`;

const HiringStepsContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 24px;

   @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
   }
`;

const StepCard = ({ step, delayFactor }) => {
   return (
      <StepContainer data-aos="fade-right" data-aos-delay={delayFactor}>
         <div className="svgWrapper">{step.icon}</div>
         <div className="textContainer">
            <p className="step-heading">{step.heading}</p>
            <p>{step.description}</p>
         </div>
      </StepContainer>
   );
};

const CallToActionContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 14px;
   padding: 24px;
   background-color: var(--light-bg-opacity-05);
   border: 1px solid var(--light-bg-opacity-20);
   border-radius: 8px;

   .textContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--text-inverted);
   }

   .call-to-action-heading {
      font-size: 21px;
      line-height: 28px;
      font-weight: 700;
   }

   .textContainer p {
      color: var(--text-muted-inverted);
   }

   .buttonsContainer a {
      box-sizing: border-box;
      width: 100%;
   }

   @media only screen and (max-width: 600px) {
   }
`;

const CallToActionCard = ({ delayFactor }) => {
   return (
      <CallToActionContainer data-aos="fade-right" data-aos-delay={delayFactor}>
         <div className="textContainer">
            <p className="call-to-action-heading">Interested?</p>
            <p>
               Contact our sales team and find out everything about our
               recruitment process.
            </p>
         </div>
         <div className="buttonsContainer">
            <Button
               content="Contact us"
               type={ButtonTypes.PRIMARY}
               url="/contact-us"
            />
         </div>
      </CallToActionContainer>
   );
};

const steps = [
   {
      icon: <HelloSVG />,
      heading: "Initial Contact",
      description:
         "Reach out to our sales team to discuss your project requirements. They’ll provide a tailored solution and a contract for your review.",
   },
   {
      icon: <GearSVG />,
      heading: "Technical Assessment",
      description:
         "Our IT specialists will delve deeper into your project to ensure a perfect match between your needs and our expertise.",
   },
   {
      icon: <FunnelSVG />,
      heading: "Talent Acquisition",
      description:
         "Our skilled recruitment team will identify and onboard top-tier IT professionals who align with your specific requirements.",
   },
   {
      icon: <RocketSVG />,
      heading: "Project Kick-off",
      description:
         "Our team will be fully prepared and briefed on your project, ensuring a smooth start.",
   },
   {
      icon: <LifeBuoySVG />,
      heading: "Ongoing Support",
      description:
         "We'll provide regular updates and support throughout the project, adjusting as needed to meet your evolving needs.",
   },
];

const HiringContent = ({ isMobile }) => {
   const delayFactor = (index) => (isMobile ? 0 : 100) * index;
   return (
      <ContentPage background="var(--gradient-bg)">
         <Container>
            <TextContainer>
               <div className="headingContainer">
                  <p className="tagline">RECRUITMENT PROCESS</p>
                  <h2>Tailored Nearshore Staffing Journey</h2>
                  <p style={{ color: "var(--text-muted-inverted)" }}>
                     Effective recruitment begins with a clear understanding of
                     your organization's specific needs. We have therefore
                     created a 5-step plan to align all parties.
                  </p>
               </div>
            </TextContainer>
            <HiringStepsContainer>
               {steps.map((step, index) => (
                  <StepCard step={step} delayFactor={delayFactor(index)} />
               ))}
               <CallToActionCard delayFactor={delayFactor(5)} />
            </HiringStepsContainer>
         </Container>
      </ContentPage>
   );
};

export default HiringContent;
