import React from "react";
import ContentSection from "./contentSection";

const CalculatorContent = ({ isMobile }) => {
   return (
      <ContentSection
         background="var(--gradient-bg)"
         heading="Curious how much will I need to spend?"
         tagline="HIREWISE"
         sectionName="calculator"
         imageAlt="Calculator"
         textColor="var(--text-inverted)"
         taglineColor="var(--accent-muted)"
         orientationInverted
         hasAnimations={false}
         isMobile={isMobile}
      />
   );
};

export default CalculatorContent;
