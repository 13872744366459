import React from "react";
import styled from "styled-components";
import CheckCircleSVG from "../../images/symbols/checkCircle.svg";

const EmailSentContainer = styled.div`
   display: flex;
   align-items: center;
   padding: 16px;
   gap: 16px;
   box-sizing: border-box;
   background: ${(props) =>
      props.isFormStyles
         ? "var(--accent-primary-05)"
         : "var(--light-bg-opacity-05)"};
   border: ${(props) =>
      props.isFormStyles
         ? "1px solid var(--accent-primary)"
         : "1px solid var(--light-bg-opacity-20)"};
   border-radius: 16px;
   width: 100%;
   color: ${(props) =>
      props.isFormStyles ? "var(--text-primary)" : "var(--text-inverted)"};

   .emailSentTextContainer {
      display: flex;
      flex-direction: column;
   }

   .emailSentTextContainer p {
      line-height: 21px;
   }

   .emailSentTextContainer .heading {
      font-weight: 700;
   }
`;

export default function EmailSentComponent({ isPdfDownload, isFormStyles }) {
   return (
      <EmailSentContainer isFormStyles={isFormStyles}>
         <CheckCircleSVG />
         <div className="emailSentTextContainer">
            <p className="heading">
               {isPdfDownload ? "Thank you!" : "Thank you for reaching out!"}
            </p>
            <p>
               {isPdfDownload
                  ? "Your download should be starting soon!"
                  : "Our team will be in touch with you shortly."}{" "}
            </p>
         </div>
      </EmailSentContainer>
   );
}
