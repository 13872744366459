import React, { useState } from "react";
import styled from "styled-components";
import ContentPage from "./contentPage";
import PiggyBankSVG from "../../images/symbols/piggyBank.svg";
import TimerSVG from "../../images/symbols/timer.svg";
import CertificateSVG from "../../images/symbols/certificate.svg";
import EnvelopeSVG from "../../images/symbols/envelope.svg";
import Button, { ButtonTypes, ButtonVariants } from "../atoms/button";
import InputComponent from "../atoms/input";
import EmailSentComponent from "../molecules/emailSent";
import { FormTypes, submitHubSpotForm } from "../../helpers/hubspotHelper";
import { sendLegislationEmail } from "../../helpers/emailClient";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   gap: 72px;
`;

const BenefitsSection = styled.div`
   display: flex;
   flex-direction: column;
   gap: 56px;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;
   color: var(--text-primary);

   .tagline {
      color: var(--accent-primary);
   }

   .headingContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 700px;
   }
`;

const BenefitContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 20px;

   .svgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 6px;
      background-color: var(--accent-primary-10);
   }

   .svgWrapper svg {
      width: 24px;
      height: 24px;
      color: var(--accent-primary);
   }

   .textContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
   }

   .benefit-heading {
      font-weight: 700;
      color: var(--text-primary);
   }

   .textContainer p {
      color: var(--text-muted);
   }
`;

const BenefitsGrid = styled.div`
   display: flex;
   gap: 40px;

   @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 36px;
   }
`;

const BenefitCard = ({
   Icon,
   heading,
   subHeading1,
   subHeading2,
   buttonLabel,
   buttonUrl,
   animationDelay,
   isMobile,
}) => {
   return (
      <BenefitContainer
         data-aos={isMobile ? "fade-up" : "fade-left"}
         data-aos-offset={isMobile ? 0 : 300}
         data-aos-delay={animationDelay}
         data-aos-duration="1000"
      >
         <div className="svgWrapper">
            <Icon />
         </div>
         <div className="textContainer">
            <p className="benefit-heading">{heading}</p>
            <p>{subHeading1}</p>
            <p>{subHeading2}</p>
         </div>
         <Button
            type={ButtonTypes.SECONDARY}
            variant={ButtonVariants.PRIMARY_COLOR}
            content={buttonLabel}
            url={buttonUrl}
         />
      </BenefitContainer>
   );
};

const LegislationContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 64px;
   width: 100%;
   box-sizing: border-box;
   padding: 64px;
   background: var(--gradient-bg);
   border-radius: 16px;
   box-shadow: 0px 4px 16px 0px #0000003d;

   .textContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 50%;
      color: var(--text-inverted);
   }

   .textContainer p {
      color: var(--text-muted-inverted);
   }

   .buttonsContainer {
      display: flex;
      gap: 16px;
      width: 50%;
   }

   @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 32px;
      padding: 24px;

      .textContainer {
         width: unset;
      }

      .buttonsContainer {
         flex-direction: column;
         width: 100%;
      }

      .buttonsContainer button,
      .buttonsContainer a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const LegislationCard = () => {
   const [email, setEmail] = useState("");
   const [isEmailValid, setIsEmailValid] = useState(false);
   const [emailSent, setEmailSent] = useState(false);

   const handleEmailChange = (e) => {
      const inputValue = e.target.value;
      setEmail(inputValue);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(inputValue));
   };

   const handleDownload = () => {
      const link = document.createElement("a");
      link.href = "/nearshorePDF.pdf";
      link.download = "Nearshore.pt - How to hire in Portugal.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };

   const handleSubmit = async () => {
      const formData = {
         email: email,
      };

      setEmailSent(true);
      const success = await submitHubSpotForm(formData, FormTypes.LEGISLATION);
      if (success) {
         handleDownload();
         sendLegislationEmail(email);
      }
   };

   return (
      <LegislationContainer>
         <div className="textContainer">
            <h4>Why Portugal?</h4>
            <p>
               Find out all the advantages of hiring in Portugal with our
               Nearshore.pt whitepaper. You can get it for free!
            </p>
         </div>
         <div className="buttonsContainer">
            {emailSent ? (
               <EmailSentComponent isPdfDownload />
            ) : (
               <>
                  <InputComponent
                     type="email"
                     value={email}
                     placeholder="Insert email address..."
                     handleChangeValue={handleEmailChange}
                     Icon={EnvelopeSVG}
                  />
                  <Button
                     content="Download PDF"
                     type={ButtonTypes.PRIMARY}
                     variant={ButtonVariants.WHITE}
                     disabled={!isEmailValid}
                     onClick={handleSubmit}
                  />
               </>
            )}
         </div>
      </LegislationContainer>
   );
};

const BenefitsContent = ({ isMobile }) => {
   const delayFactor = (index) => 100 * index;
   return (
      <ContentPage background="var(--light-bg)">
         <Container>
            <BenefitsSection>
               <TextContainer>
                  <div className="headingContainer">
                     <p
                        className="tagline"
                        data-aos="fade-up"
                        data-aos-delay={delayFactor(0)}
                     >
                        BENEFITS
                     </p>
                     <h2 data-aos="fade-up" data-aos-delay={delayFactor(1)}>
                        Why Nearshore.pt?
                     </h2>
                     <p data-aos="fade-up" data-aos-delay={delayFactor(2)}>
                        Nearshore.pt makes use of Caixa Mágica’s recruitment
                        process. Which has been honed to attract the best
                        software professionals and refined to make onboarding as
                        smooth as possible.
                     </p>
                  </div>
               </TextContainer>
               <BenefitsGrid>
                  <BenefitCard
                     Icon={PiggyBankSVG}
                     heading="Top-Tier Development starting at €30/Hour"
                     subHeading1="Through our 20 years of experience with staffing and our technological expertise, we have found ways to optimize our staffing services."
                     subHeading2="Reliant on technology and process optimization, we are capable of delivering development for just €30/hour."
                     buttonLabel="Calculate for free"
                     buttonUrl="/hirewise"
                     animationDelay={delayFactor(2)}
                     isMobile={isMobile}
                  />
                  <BenefitCard
                     Icon={TimerSVG}
                     heading="Hire Top IT Talent in Just 2 Weeks"
                     subHeading1="We understand that timeframes and deadlines have a reason and can make or break a project."
                     subHeading2="In Caixa Mágica, we face similar difficulties and therefore have developed a recruitment process capable of staffing IT-professionals within 2 weeks."
                     buttonLabel="Contact sales"
                     buttonUrl="/contact-us"
                     animationDelay={delayFactor(2)}
                     isMobile={isMobile}
                  />
                  <BenefitCard
                     Icon={CertificateSVG}
                     heading="Unmatched Flexibility"
                     subHeading1="Not only the prices at Caixa Mágica are superb, also the flexibility is unparalleled."
                     subHeading2="Starting at 1 month, we can offer a flexible contract to support your team for a short time as well as be your partner in growth."
                     buttonLabel="Send us a message"
                     buttonUrl="/contact-us"
                     animationDelay={delayFactor(2)}
                     isMobile={isMobile}
                  />
               </BenefitsGrid>
            </BenefitsSection>
            <LegislationCard />
         </Container>
      </ContentPage>
   );
};

export default BenefitsContent;
