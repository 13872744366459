import React from "react";
import styled from "styled-components";
import ContentPage from "./contentPage";
import ServiceImage1 from "../../images/serviceImg1.jpeg";
import ServiceImage2 from "../../images/serviceImg2.jpeg";
import CalculatorCard from "./calculatorCard";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   gap: 64px;
`;

const ServicesSection = styled.div`
   display: flex;
   flex-direction: column;
   gap: 64px;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;
   color: var(--text-primary);

   .tagline {
      color: var(--accent-primary);
   }

   h2 {
      max-width: 640px;
   }

   .subheading {
      max-width: 720px;
   }

   .headingContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
`;

const ServicesGrid = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 32px;

   @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
   }
`;

const ServiceContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   padding: 120px 40px 40px 40px;
   background-image: linear-gradient(
         243.14deg,
         rgba(11, 15, 25, 0) 20.7%,
         #0b0f19 72.89%
      ),
      linear-gradient(0deg, rgba(11, 15, 25, 0.3), rgba(11, 15, 25, 0.3)),
      url(${(props) => props.bgImage});
   background-size: cover;
   background-position: center;
   box-shadow: 0px 2px 10px 0px #0000001a;

   color: var(--text-inverted);

   border-radius: 16px;
   gap: 10px;

   @media only screen and (max-width: 600px) {
      padding: 120px 24px 24px 24px;
   }
`;

const ServiceCard = ({ heading, content, bgImage }) => {
   return (
      <ServiceContainer bgImage={bgImage}>
         <h3>{heading}</h3>
         <p>{content}</p>
      </ServiceContainer>
   );
};

const ServicesContent = ({ isMobile }) => {
   return (
      <ContentPage background="var(--medium-bg)">
         <Container>
            <ServicesSection>
               <TextContainer>
                  <div className="headingContainer">
                     <p className="tagline">SERVICES</p>
                     <h2>
                        We provide flexible IT-staffing Solutions tailored to
                        your needs
                     </h2>
                  </div>
                  <p className="subheading">
                     Upgrade your software development or create an entirely new
                     a new team. Our Nearshore staffing services are designed to
                     help you quickly and efficiently. With seamless
                     collaboration and dedicated support, we help you drive
                     innovation and stay ahead in the market.
                  </p>
               </TextContainer>
               <ServicesGrid>
                  <ServiceCard
                     heading="New team"
                     content="Use our Nearshore staffing services to build a talented team from scratch. Completely tailored  to your project's needs. We handle the recruitment, onboarding, and integration to guarantee the best outcome."
                     bgImage={ServiceImage1}
                  />
                  <ServiceCard
                     heading="Upgrade team"
                     content="Enhance your existing team with top talent to meet project demands or add specialized skills. Our experts integrate seamlessly into your workflow, adapting to your tools, processes, and goals for a smooth collaboration experience."
                     bgImage={ServiceImage2}
                  />
                  {isMobile && <CalculatorCard />}
               </ServicesGrid>
            </ServicesSection>
         </Container>
      </ContentPage>
   );
};

export default ServicesContent;
