import React from "react";
import styled from "styled-components";
import Button, { ButtonTypes } from "../atoms/button";
import bgImg from "../../images/landingImg.png";

const LandingContainer = styled.div`
   background-image: url(${bgImg});
   background-size: cover;
   background-attachment: fixed;
   background-position: center;
   background-repeat: no-repeat;
   height: 100vh;
   display: flex;
   justify-content: center;
`;

const HomeContainer = styled.div`
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
   z-index: 2;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;
   color: var(--text-inverted);

   .subheading {
      max-width: 640px;
   }

   h1 {
      max-width: 766px;
   }
`;

const ButtonsContainer = styled.div`
   display: flex;
   gap: 16px;

   @media only screen and (max-width: 600px) {
      flex-direction: column;

      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const LandingContent = () => {
   const delayFactor = (index) => 100 * index;
   return (
      <LandingContainer>
         <HomeContainer>
            <TextContainer>
               {/* <p className="tagline" data-aos="fade-up">
                  NEARSHORE.PT
               </p> */}
               <h1
                  className="tagline"
                  data-aos="fade-up"
                  data-aos-delay={delayFactor(1)}
               >
                  Quality IT professionals at prices that make sense
               </h1>
               <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay={delayFactor(2)}
               >
                  Through our years of staffing experience and our understanding
                  of technology, we are able to provide you with Top-tier
                  European talent at competitive rates.
               </p>
            </TextContainer>
            <ButtonsContainer>
               <Button
                  content="Contact us"
                  type={ButtonTypes.PRIMARY}
                  url="/contact-us"
                  data-aos="fade-up"
                  data-aos-delay={delayFactor(3)}
               />
               <Button
                  content="Calculate for free"
                  type={ButtonTypes.SECONDARY}
                  data-aos="fade-up"
                  url="/hirewise"
                  data-aos-delay={delayFactor(4)}
               />
            </ButtonsContainer>
         </HomeContainer>
      </LandingContainer>
   );
};

export default LandingContent;
