import React from "react";
import ContentSection from "./contentSection";

const AboutUsContent = ({ isMobile }) => {
   return (
      <ContentSection
         background="var(--light-bg)"
         heading="Over 20 Years of excellence in the IT Market"
         tagline="ABOUT US"
         boldSubheading="Signed by José Rodrigues, CEO of Caixa Mágica"
         sectionName="aboutUs"
         imageAlt="About Us"
         textColor="var(--text-primary)"
         taglineColor="var(--accent-primary)"
         isMobile={isMobile}
      />
   );
};

export default AboutUsContent;
